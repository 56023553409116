<template>
  <!-- toast -->
  <b-toast
    id="loading-toast"
    no-close-button
    no-auto-hide
    :variant="variant"
  >
    <template #toast-title >
      <div class="d-flex flex-grow-1 align-items-center mr-25">
        <strong class="mr-auto" v-text="title" />
        <!-- <small class="text-muted">11 mins ago</small> -->
      </div>
    </template>
    <svg style="height: 30px;"
      version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve" class="omniview__camera__video-container__loading">
      <path fill="#971f1e" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite" />
      </path>
    </svg>
    <span
      v-if="text"
      v-text="text"
    />
  </b-toast>
</template>

<script>
import { BToast, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BToast,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup(_, context) {
    const title = ref('')
    const text = ref('')
    const variant = ref('')

    const show = (_title, _text, _variant) => {
      title.value = _title
      text.value = _text
      variant.value = _variant
      context.parent.$bvToast.show('loading-toast')
    }

    const close = () => {
      context.parent.$bvToast.hide('loading-toast')
    }

    return {
      show,
      close,

      title,
      text,
      variant
    }
  }
}
</script>
