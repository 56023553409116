<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
    />

    <toastification-loading-content
      ref="loadingToast"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t('table.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t('table.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                variant="primary"
                @click="openAdd()"
                v-if="$can('create', 'TypeAlarm')"
              >
                <span class="text-nowrap text-capitalize">
                  {{ `${$t('actions.add')} ${$tc('types_alarms.title', 1)}` }}
                </span>
              </b-button>
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon
                  icon="DownloadCloudIcon"
                  size="15"
                />
              </b-button>
            </div>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="openEdit(data.item)"
              v-if="$can('update', 'TypeAlarm')"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t('actions.edit')
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="$can('delete', 'TypeAlarm')"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t('actions.delete')
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t('table.showing', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ref} from '@vue/composition-api'
import useList from './useList'
import Sidenav from './Sidenav.vue'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import ToastificationLoadingContent
  from '@core/components/toastification/ToastificationLoadingContent.vue'
import store from '@/store'
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Sidenav,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    Pagination,
    ToastificationLoadingContent,
    vSelect,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const isSidebarActive = ref(false)
    const itemEdit = ref({})
    const isAdd = ref(false)
    const buttonExport = ref(false)

    const loadingToast = ref(null)

    const openEdit = (item) => {
      isSidebarActive.value = true
      itemEdit.value = item
      isAdd.value = false
    }

    const toast = useToast()

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,

      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,

      action,
    } = useList()

    const confirmDelete = function (id) {
      const self = this
      const storeInner = store
      this.$swal({
        title: context.parent.$i18n.t('messages.delete.title'),
        text: context.parent.$i18n.t('messages.delete.body'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t('actions.delete'),
        cancelButtonText: context.parent.$i18n.t('actions.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary text-capitalize',
          cancelButton: 'btn btn-outline-danger ml-1 text-capitalize',
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            storeInner
              .dispatch('type_alarm/delete', id)
              .then((response) => {
                if (response.success) {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  self.refetchData()
                } else {
                  toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: response.message,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      timeout: 10000,
                    }
                  )
                }
              })
              .catch((response) => {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.response.data.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    timeout: 10000,
                  }
                )
              })
          }
        })
    }

    const exportExcel = () => {
      action.value = 'export'
      fetchList()
    }

    const openAdd = () => {
      isAdd.value = true
      isSidebarActive.value = true
    }

    return {
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      update,
      exportExcel,
      loadingToast,
      buttonExport,
    }
  },
}
</script>