<template>
  <div id="my-container">
    <div id="popover-reactive-1">&nbsp;</div>
    <b-pagination
      v-model=pagePagination
      :total-rows="totalList"
      :per-page="perPage"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18"/>
      </template>
      <template #page="{ page, active }">
        <span v-if="active" @dblclick="onOpen()">
          {{ page }}
        </span>
        <span v-else>{{ page }}</span>
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18"/>
      </template>
    </b-pagination>
    <b-popover
      target="popover-reactive-1"
      placement="auto"
      container="my-container"
      ref="popover"
      :show.sync="popoverShow"
    >
      <template #title>
        {{ $t("pagination.title") }}
      </template>

      <div>
        <b-form-group
          :label="$t('pagination.page')"
          label-for="popover-input-1"
          label-cols="3"
          :state="input1state"
          class="mb-1"
          :invalid-feedback="$t('pagination.page_not_found')"
        >
          <b-form-input
            id="popover-input-1"
            v-model="input1"
            :state="input1state"
            ref="input1"
            size="sm"
            v-focus="focused"
            @keyup.enter="onOk"
          ></b-form-input>
        </b-form-group>
        <b-row class="text-center">
          <b-col md="6">
            <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
          </b-col>
          <b-col md="6">
            <b-button @click="onClose" size="sm" variant="danger">
              {{ $t("pagination.cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-popover>
  </div>
</template>
<script>
import {
  BButton,
  BAlert,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BPopover,
  BCard,
  BPagination,
} from "bootstrap-vue";
import {focus} from 'vue-focus';

export default {
  directives: {focus: focus},
  components: {
    BPagination,
    BButton,
    BAlert,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BPopover,
    BCard,
  },
  props:
    ["totalList",
      "perPage", "currentPage"],
  data() {
    return {
      focused: false,
      pagePagination: 1,
      input1: '',
      input1state: null,
      popoverShow: false
    }
  },
  computed: {
    pagination() {
      return Math.round(this.totalList / this.perPage)
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    onOpen() {
      this.popoverShow = !this.popoverShow
      this.clear()
      this.focused = true;
    },
    onOk() {
      if (this.input1 === " ") {
        this.onClose()
      } else {
        if (this.input1 <= this.pagination) {
          this.input1state = true
          this.onClose()
          this.pagePagination = this.input1
          this.$emit("updatePagination", this.pagePagination);
          this.focused = false;
        } else {
          this.input1state = false
        }
        if (!this.input1) {
          this.input1state = false
        }
      }
    },
    clear() {
      this.input1 = " "
      this.input1state = null
    },
  },
  watch: {
    pagePagination: function () {
      this.$emit("updatePagination", this.pagePagination);
    }
  }
}
</script>
